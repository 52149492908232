import React from "react";
import { graphql, PageProps } from "gatsby";

import { Query } from "@graphql-types";
import {
  FullScreenContentBlock,
} from "@components";
import { ProviderContainer } from "@global";
import { useStore } from "@state/store";

interface Props extends PageProps {
  data: Query;
}

export default function ThankYouPage({ data }: Props) {
  const { user } = useStore();
  const { nodes } = data.allSanityThankYou;
  const { fullScreenContentBlock, password } = nodes[0];

  return (
    <ProviderContainer password={password} isAdmin={user?.isAdmin}>
      <FullScreenContentBlock data={fullScreenContentBlock} />
    </ProviderContainer>
  );
}

export const query = graphql`
  query ThankYouQuery($region: String, $slug: String) {
    allSanityThankYou(filter: { 
      region: { iban: { eq: $region } } 
      slug: { current: { eq: $slug } }
    }) {
      nodes {
        password
        region {
          ...sanityRegion
        }
        fullScreenContentBlock{
          ...sanityFullScreenContentBlock
        }
      }
    }
  }
`;
